// @flow strict
import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="What the dickens?">
        <p>You boldly went where no one has gone before.</p>
        <p>Except it's not so much undiscovered country as non-existent. Sorry.</p>
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
